import { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Toaster } from "sonner";
import "./App.css";
import AddCustomGroup from "./components/AddCustomGroup";
import AddCustomSearch from "./components/AddCustomSearch";
import AEHeader from "./components/AEHeader";
import Header from "./components/Header";
import Instructions from "./components/Instructions";
import PermissionPopup from "./components/PermissionPopup";
import SDSMarks from "./components/SDSMarks";
import Search from "./components/Search";
import Sites from "./components/Sites";
import SubscriptionForm from "./components/SubscriptionForm";

const sitesInitial = {
  Image: {
    prefix: "image",
    links: [
      {
        id: "Google Images",
        url: "https://www.google.com/search?q={query}&tbm=isch",
        active: false,
        category: "",
        fixed: true,
      },
      {
        id: "Pinterest",
        url: "https://www.pinterest.com/search/pins/?q={query}",
        active: false,
        category: "",
        fixed: true,
      },
      {
        id: "Flickr",
        url: "https://www.flickr.com/search/?q={query}&w=all",
        active: false,
        category: "",
        fixed: true,
      },
    ],
  },
  Video: {
    prefix: "video",
    links: [
      {
        id: "YouTube",
        url: "https://www.youtube.com/results?search_query={query}",
        active: true,
        category: "",
        fixed: true,
      },
    ],
  },
  "Search Engine": {
    prefix: "search engine",
    links: [
      {
        id: "Google",
        url: "https://www.google.com/search?q={query}",
        active: true,
        category: "",
        fixed: true,
      },
      {
        id: "Bing",
        url: "https://www.bing.com/search?q={query}",
        active: false,
        category: "",
        fixed: true,
      },
      {
        id: "DuckDuckGo",
        url: "https://duckduckgo.com/?q={query}",
        active: false,
        category: "",
        fixed: true,
      },
    ],
  },
  Shop: {
    prefix: "shop",
    links: [
      {
        id: "eBay",
        url: "https://www.ebay.com/sch/i.html?_nkw={query}",
        active: true,
        category: "",
        fixed: true,
      },
      {
        id: "Amazon",
        url: "https://www.amazon.com/s?k={query}",
        active: false,
        category: "",
        fixed: true,
      },
    ],
  },
  "Social Media": {
    prefix: "social media",
    links: [
      {
        id: "Twitter",
        url: "https://twitter.com/search?q={query}",
        active: true,
        category: "",
        fixed: true,
      },
      {
        id: "Linkedln",
        url: "https://www.linkedin.com/search/results/all/?keywords={query}&sid=A%3AF",
        active: false,
        category: "",
        fixed: true,
      },

      {
        id: "Facebook",
        url: "https://www.facebook.com/search/top/?q={query}",
        active: false,
        category: "",
        fixed: true,
      },
    ],
  },
  Other: {
    prefix: "",
    links: [
      {
        id: "Google Maps",
        url: "https://www.google.com/maps/search/{query}/",
        active: false,
        category: "",
        fixed: true,
      },
      {
        id: "Google Translate",
        url: "https://translate.google.com/?text={query}",
        active: false,
        category: "",
        fixed: true,
      },
      {
        id: "Gmail",
        url: "https://mail.google.com/mail/u/0/#search/{query}",
        active: false,
        category: "",
        fixed: true,
      },
      {
        id: "Google Drive",
        url: "https://drive.google.com/drive/u/0/search?q={query}",
        active: false,
        category: "",
        fixed: true,
      },
      {
        id: "Wikipedia",
        url: "https://en.wikipedia.org/wiki/{query}",
        active: false,
        category: "",
        fixed: true,
      },
      {
        id: "IMDB",
        url: "https://www.imdb.com/find?s=all&q={query}",
        active: false,
        category: "",
        fixed: true,
      },
      {
        id: "Wolfram Alpha",
        url: "https://www.wolframalpha.com/input/?i={query}",
        active: false,
        category: "",
        fixed: true,
      },
      {
        id: "Rotten Tomatoes",
        url: "https://www.rottentomatoes.com/search?search={query}",
        active: false,
        category: "",
        fixed: true,
      },
    ],
  },
};

function App() {
  const [toEdit, setToEdit] = useState("");
  const [sitesList, setSitesList] = useState(
    localStorage.getItem("sitesList")
      ? JSON.parse(localStorage.getItem("sitesList"))
      : sitesInitial
  );

  useEffect(() => {
    if (!localStorage.getItem("sitesList")) {
      localStorage.setItem("sitesList", JSON.stringify(sitesInitial));
    }
  }, []);

  return (
    <>
      <AEHeader />
      <div className="App">
        <Header />
        <Search sitesList={sitesList} />
        <Instructions />
        <Sites
          sitesList={sitesList}
          setSitesList={setSitesList}
          setToEdit={setToEdit}
        />
        <AddCustomGroup
          sitesList={sitesList}
          setSitesList={setSitesList}
          toEdit={toEdit}
          setToEdit={setToEdit}
        />
        <AddCustomSearch sitesList={sitesList} setSitesList={setSitesList} />
        <SubscriptionForm />
        <SDSMarks />
        <div className="roadmap">
          <div>HeftySearch v2.0</div>
          <a
            href="https://jarren.notion.site/HeftySearch-Roadmap-caf2ef3633944adfb58d383e9c381c5b"
            target="_blank"
            rel="noreferrer"
          >
            View our Product Roadmap
          </a>
          <PermissionPopup />
        </div>
      </div>
      <Toaster richColors />
    </>
  );
}

export default App;
