import { useState } from "react";

const Search = ({ sitesList }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const findPrefix = (term) => {
    let found = false;

    Object.keys(sitesList).map((e, i) => {
      if (sitesList[e].prefix) {
        if (term.indexOf(sitesList[e].prefix + " ") === 0) {
          sitesList[e].links.map((site) => {
            if (site.active) {
              const url = site.url.replace(
                "{query}",
                searchTerm.replace(sitesList[e].prefix + " ", "")
              );
              window.open(url);
            }
          });
          found = true;
        }
      }
    });

    return found;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (findPrefix(searchTerm)) {
      return;
    }

    Object.keys(sitesList).map((e, i) => {
      sitesList[e].links.map((site) => {
        if (site.active) {
          const url = site.url.replace("{query}", searchTerm);
          window.open(url);
        }
      });
    });
  };

  return (
    <form onSubmit={handleSubmit} className="search-form">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search all active sites..."
        required
      />
      <button type="submit">
        <img src="https://heftysearch.com/search-icon.png" alt="Search" />
      </button>
    </form>
  );
};

export default Search;
