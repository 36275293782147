import { useState } from "react";

const Instructions = () => {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow((prevValue) => !prevValue);

  return (
    <div className="instructions">
      <p onClick={toggleShow}>How does it work?</p>
      <ol className={show ? "instruction-list show" : "instruction-list"}>
        <li>
          Type whatever you want to look for in the search bar at the top. The
          highlighted options will provide results. To add/remove an option,
          just click on it.
        </li>
        <li>
          You may opt to include a limited amount of options in your search
          results. To do that, add the specified prefix at the beginning of the
          term you're searching (e.g.{' '}
          <span className="example"> video how to assemble a chair</span>).
          Only options that are currently active will yield results.
        </li>
        <li>
          You can drag and drop options to be used with different prefixes
        </li>
        <li>
          You can also add custom groups or searches at the bottom of the page.
        </li>
      </ol>
    </div>
  );
};

export default Instructions;
