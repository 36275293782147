const Header = () => {
  return (
    <header>
      <h1>Hefty Search</h1>
      <h3>Search Bigger. Search Better. Search everywhere at once.</h3>
    </header>
  );
};

export default Header;
