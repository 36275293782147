import { useState } from "react";

const initialForm = { name: "", url: "", active: true, fixed: false };

const AddCustomSearch = ({ sitesList, setSitesList }) => {
  const [show, setShow] = useState(false);
  const [showHowWorks, setShowHowWorks] = useState(false);
  const [form, setForm] = useState(initialForm);

  const handleSubmit = (e) => {
    e.preventDefault();

    let auxList = { ...sitesList };

    auxList.Other.links.push({
      id: form.name,
      url: form.url,
      active: true,
      fixed: true,
    });

    localStorage.setItem("sitesList", JSON.stringify(auxList));
    setSitesList(auxList);

    setForm(initialForm);
    setShow(false);
    setShowHowWorks(false);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className={show ? "show custom-container" : "custom-container"}>
        <button className="show-custom-btn" onClick={() => setShow(!show)}>
          Add Custom Search
        </button>
        <form
          className={show ? "custom-form show" : "custom-form"}
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            value={form.name}
            onChange={handleChange}
            name="name"
            placeholder="name"
            required
          />
          <input
            type="text"
            value={form.url}
            onChange={handleChange}
            name="url"
            placeholder="https://www.facebook.com/marketplace/search/?query=test"
            required
          />
          <div className="tip">
            Replace your search term with "&#123;query&#125;"
          </div>
          <div className="bottom-bar">
            <button
              type="button"
              onClick={() => setShowHowWorks(!showHowWorks)}
            >
              How does this work?
            </button>
            <button type="submit">Add Custom Search</button>
          </div>
        </form>
      </div>
      <div
        className={
          showHowWorks ? "how-works-container show" : "how-works-container"
        }
      >
        <div>
          To build a custom search, use replace your search in the URL with
          "&#123;query&#125;".
        </div>
        <div>
          For example, if you want to search Facebook marketplace, search the
          website for the word “test”, and copy the URL.
        </div>
        <div className="div-input">
          https://www.facebook.com/marketplace/search/?query=<b>test</b>
        </div>
        <div className="i">
          Sometimes the term might be hard to find, but it should be somewhere
          in the URL.
        </div>
        <div>Then replace your word "test" with "&#123;query&#125;"</div>
        <div className="div-input">
          https://www.facebook.com/marketplace/search/?query=
          <b>&#123;query&#125;</b>
        </div>
        <div>Finally add your new URL to the custom search box!</div>
      </div>
    </>
  );
};

export default AddCustomSearch;
