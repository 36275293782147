const AEHeader = () => (
  <a
    href="https://ae.studio?utm_source=sds&utm_medium=referral&utm_campaign=heftysearch&utm_content=top-bar&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252"
    rel="noreferrer"
    target="_blank"
    data-analytics="learn-more-about-ae-link"
    className="ae-header"
  >
    <span className="font-medium">
      Made with 🧡 by <span className="underline">AE Studio</span>
    </span>
    <span className="desktop-only">•</span>
    <span className="desktop-only">See what we could build for you</span>
    <div className="desktop-only">
      <span className="font-semibold underline">Learn more →</span>
    </div>
  </a>
);

export default AEHeader;
