import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { confirmAlert } from "react-confirm-alert"; // Import

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,
  borderRadius: "7px",

  // change background colour if dragging
  background: isDragging ? "rgba(0, 0, 0, .3)" : "#f3f3f3",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "transparent" : "transparent",
  padding: "20px 0",
  transition: ".3s",
  marginBottom: isDraggingOver ? "69px" : "0",
});

const Sites = ({ sitesList, setSitesList, setToEdit }) => {
  const handleActive = (key, idx) => {
    let auxList = { ...sitesList };
    auxList[key].links[idx].active = !auxList[key].links[idx].active;
    localStorage.setItem("sitesList", JSON.stringify(auxList));
    setSitesList(auxList);
  };

  const getList = (id) => sitesList[id].links;

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const sitesList2 = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      localStorage.setItem(
        "sitesList",
        JSON.stringify({
          ...sitesList,
          [source.droppableId]: {
            ...sitesList[source.droppableId],
            links: sitesList2,
          },
        })
      );

      setSitesList({
        ...sitesList,
        [source.droppableId]: {
          ...sitesList[source.droppableId],
          links: sitesList2,
        },
      });
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      var firstKey = Object.keys(result)[0];
      var secondKey = Object.keys(result)[1];

      localStorage.setItem(
        "sitesList",
        JSON.stringify({
          ...sitesList,
          [firstKey]: { ...sitesList[firstKey], links: result[firstKey] },
          [secondKey]: { ...sitesList[secondKey], links: result[secondKey] },
        })
      );

      setSitesList({
        ...sitesList,
        [firstKey]: { ...sitesList[firstKey], links: result[firstKey] },
        [secondKey]: { ...sitesList[secondKey], links: result[secondKey] },
      });
    }
  };

  const getUrlDomain = (url) => {
    try {
      let domain = new URL(url);
      return "https://icons.duckduckgo.com/ip3/" + domain.hostname + ".ico";
    } catch (e) {
      return "";
    }
  };

  const handleCheckAll = (key) => {
    let auxList = { ...sitesList };

    const keyValues = auxList[key].links;

    const isAllSelected = keyValues.every((item) => item.active);

    if (isAllSelected) {
      keyValues.map((item, idx) => {
        keyValues[idx].active = false;
      });
    } else {
      keyValues.map((item, idx) => {
        keyValues[idx].active = true;
      });
    }

    auxList[key].links = keyValues;

    localStorage.setItem("sitesList", JSON.stringify(auxList));
    setSitesList(auxList);
  };

  const handleDelete = (key, idx) => {
    confirmAlert({
      title: "Delete " + sitesList[key].links[idx].id,
      buttons: [
        {
          className: "confirm-delete-btn",
          label: "Confirm",
          onClick: () => {
            let auxList = { ...sitesList };
            let keyToRemove = auxList[key].links;

            keyToRemove.splice(idx, 1);

            auxList[key].links = keyToRemove;

            localStorage.setItem("sitesList", JSON.stringify(auxList));
            setSitesList(auxList);
          },
        },
        {
          label: "Cancel",
          className: "cancel-delete-btn",
        },
      ],
    });
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {Object.keys(sitesList).map((e, i) => {
        return (
          <div key={i} className="sites-container">
            <div className="cat-title">
              <span>
                {e} -{" "}
                <i
                  className="prefix"
                  title={`Prefix - type ${sitesList[e].prefix} + search term on search bar to search only this group`}
                >
                  Prefix: {sitesList[e].prefix}
                </i>
              </span>
              <div>
                {e !== "Other" && (
                  <svg
                    onClick={() => setToEdit(e)}
                    height="20"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    ></path>
                  </svg>
                )}
                <img
                  src="/check-all-icon-20.jpeg"
                  onClick={() => handleCheckAll(e)}
                  alt="Check all"
                  title="Check/Uncheck all"
                />
              </div>
            </div>
            <Droppable droppableId={e}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {sitesList[e].links.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={item.active ? "site" : "site inactive"}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div
                            onClick={() => handleActive(e, index)}
                            className={
                              item.active ? "status active" : "status inactive"
                            }
                          ></div>{" "}
                          {item.url && (
                            <img
                              onClick={() => handleActive(e, index)}
                              src={getUrlDomain(item.url)}
                              alt={item.id + " logo"}
                            />
                          )}
                          <div
                            className="name-container"
                            onClick={() => handleActive(e, index)}
                          >
                            {item.id}
                          </div>
                          <div
                            className="delete-container"
                            onClick={() => handleDelete(e, index)}
                          >
                            <img src="/x.png" alt="Delete button" />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </div>
        );
      })}
    </DragDropContext>
  );
};

export default Sites;
