import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import

const initialForm = { name: "", prefix: "" };

const AddCustomGroup = ({ sitesList, setSitesList, toEdit, setToEdit }) => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState(initialForm);

  const handleSubmit = (e) => {
    e.preventDefault();

    let auxList = { ...sitesList };

    if (!toEdit) {
      auxList[form.name] = {
        prefix: form.prefix,
        links: auxList[form.name]?.links || [],
      };

      localStorage.setItem("sitesList", JSON.stringify(auxList));
      setSitesList(auxList);
    } else {
      const dataItem = auxList[toEdit];
      delete auxList[toEdit];

      auxList[form.name] = dataItem;
      auxList[form.name].prefix = form.prefix;

      localStorage.setItem("sitesList", JSON.stringify(auxList));
      setSitesList(auxList);
    }

    setForm(initialForm);
    setShow(false);
    setToEdit("");
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCancelEdit = () => {
    setShow(false);
    setToEdit("");
    setForm(initialForm);
  };

  const handleDeleteGroup = () => {
    if (sitesList[toEdit].links.length > 0) {
      confirmAlert({
        title: toEdit + " list is not empty",
        message: "Move or delete items in " + toEdit + " to delete this group.",
        buttons: [
          {
            label: "Ok",
            className: "cancel-delete-btn",
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Delete " + toEdit + "?",
        buttons: [
          {
            className: "confirm-delete-btn",
            label: "Confirm",
            onClick: () => {
              let auxList = { ...sitesList };

              delete auxList[toEdit];

              localStorage.setItem("sitesList", JSON.stringify(auxList));
              setSitesList(auxList);

              setShow(false);
              setForm(initialForm);
              setToEdit("");
            },
          },
          {
            label: "Cancel",
            className: "cancel-delete-btn",
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (toEdit) {
      setShow(true);
      setForm({ name: toEdit, prefix: sitesList[toEdit].prefix });
      document.getElementById("customname").focus();
    }
  }, [toEdit]);

  return (
    <div className={show ? "show custom-container" : "custom-container"}>
      <button className="show-custom-btn" onClick={() => setShow(!show)}>
        {toEdit ? "Edit " : "Add "} Custom Group
      </button>
      <form
        className={show ? "custom-form show" : "custom-form"}
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          value={form.name}
          onChange={handleChange}
          name="name"
          placeholder="Name"
          id="customname"
          required
        />
        <input
          type="text"
          value={form.prefix}
          onChange={handleChange}
          name="prefix"
          placeholder="Prefix"
          required
        />
        {/* <div className="tip">Example - Name: Images, Prefix: i</div> */}
        <div className="bottom-bar">
          <div></div>
          <div>
            {toEdit && (
              <span>
                <button
                  type="button"
                  onClick={handleDeleteGroup}
                  style={{ marginRight: "20px" }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  onClick={handleCancelEdit}
                  style={{ marginRight: "20px" }}
                >
                  Cancel
                </button>
              </span>
            )}
            <button type="submit">{toEdit ? "Save" : "Add Group"}</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCustomGroup;
