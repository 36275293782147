const SDSMarks = () => {
  return (
    <>
      <img
        src="https://ae.studio/img/same-day-skunkworks/watermark.png"
        alt="Same day skunkworks"
        className="watermark rotating"
        onClick={() => window.open("https://ae.studio?utm_source=sds&utm_medium=referral&utm_campaign=heftysearch&utm_content=footer&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252")}
      />
      {/* <div className="love">
        Made with ♥ by{" "}
        <a href="https://ae.studio?utm_source=sds&utm_medium=referral&utm_campaign=heftysearch&utm_content=footer&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252" target="_blank" rel="noopener noreferrer">
          Agency Enterprise
        </a>
      </div> */}
    </>
  );
};

export default SDSMarks;
