import { useEffect, useState } from "react";

const PermissionPopup = () => {
  const [show, setShow] = useState(
    localStorage.getItem("showPermissionPopup")
      ? localStorage.getItem("showPermissionPopup") === "true"
        ? true
        : false
      : true
  );

  const handleClose = () => {
    setShow(false);
    localStorage.setItem("showPermissionPopup", false);
  };

  useEffect(() => {
    if (!localStorage.getItem("showPermissionPopup")) {
      localStorage.setItem("showPermissionPopup", true);
    }
  }, []);

  return (
    <>
      {show && (
        <div className="permission-popup">
          <img
            src="https://heftysearch.com/permission-icon.png"
            alt="Permission icon"
          />
          <div>
            After your first search, you may need to enable popups for Hefty
            Search to function correctly.
          </div>
          <div className="close-icon" onClick={handleClose}>
            x
          </div>
        </div>
      )}
    </>
  );
};

export default PermissionPopup;
